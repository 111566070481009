class DifficultySelect {
    constructor(name) {
        this.comparator = document.getElementById("dropdown__rank_" + name + "__value");
        this.year = document.getElementById("dropdown__year_" + name + "__value");
        this.rowHeadings = document.getElementsByClassName("difsel__" + name);
        this.colHeadings = [
            document.getElementById(name + "_easy"),
            document.getElementById(name + "_medium"),
            document.getElementById(name + "_hard"),
        ];
        for (let i = 0; i < this.rowHeadings.length; i++) {
            this.rowHeadings[i].onclick = () => {
                this.toggleRow(i)
            };
        }

        for (let i = 0; i < this.colHeadings.length; i++) {
            this.colHeadings[i].onclick = () => {
                this.toggleCol(i)
            };
        }

        this.options = [];
        for (let i = 0; i < this.rowHeadings.length; i++) {
            this.options[i] = [];
            this.options[i][0] = document.getElementById("y" + (i + 1) + "o0__" + name);
            this.options[i][1] = document.getElementById("y" + (i + 1) + "o1__" + name);
            this.options[i][2] = document.getElementById("y" + (i + 1) + "o2__" + name);
        }


        let comparator_input = document.getElementById("dropdown__rank_" + name + "__input");
        let year_input = document.getElementById("dropdown__year_" + name + "__input");
        comparator_input.addEventListener("blur", () => {
            let valueBef = this.comparator.value;
            setTimeout(() => {
                if (this.comparator.value != valueBef) {
                    this.selectOptions();
                }
            }, 150);
        });

        year_input.addEventListener("blur", () => {
            let valueBef = this.year.value;
            setTimeout(() => {
                if (comparator_input.value != valueBef) {
                    this.selectOptions();
                }
            }, 150);
        });

        this.setAllHeaders(false);
    }

    setAllHeaders(value) {
        for (let i = 0; i < this.rowHeadings.length; i++) {
            this.rowHeadings[i].dataset.checked = value;
        }

        for (let i = 0; i < this.colHeadings.length; i++) {
            this.colHeadings[i].dataset.checked = value;
        }
    }

    selectOptions() {
        let year = 1 * this.year.value;
        let comp = this.comparator.value;
        if (year == undefined || comp == undefined)
            return;
        for (let i = 0; i < this.rowHeadings.length; i++) {
            for (let j = 0; j < 3; j++) {
                this.options[i][j].checked = this.compare(this.options[i][j].dataset.rank, year, comp);
            }
        }
    }

    toggleCol(j) {
        let setTo = false;
        if (this.colHeadings[j].dataset.checked == "false") {
            setTo = true;
        }

        for (let i = 0; i < this.options.length; i++) {
            this.options[i][j].checked = setTo;
        }

        this.colHeadings[j].dataset.checked = "" + setTo;
    }

    toggleRow(i) {
        let setTo = false;
        if (this.rowHeadings[i].dataset.checked == "false") {
            setTo = true;
        }

        for (let j = 0; j < 3; j++) {
            this.options[i][j].checked = setTo;
        }

        this.rowHeadings[i].dataset.checked = "" + setTo;
    }

    compare(opt, year, comp) {
        switch (comp) {
            case "=":
                return opt == year;
            case ">":
                return opt > year;
            case "<":
                return opt < year;
            case "≤":
                return opt <= year;
            case "≥":
                return opt >= year;
        }

        return false;
    }
}