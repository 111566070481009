// Windows 8 mobile Responsive utility
if (navigator.userAgent.match(/IEMobile\/10\.0/)) {
	var msViewportStyle = document.createElement('style')
	msViewportStyle.appendChild(
		document.createTextNode(
			'@-ms-viewport{width:auto!important}'
		)
	)
	document.querySelector('head').appendChild(msViewportStyle)
}

// Jquery functions
jQuery(document).ready(function () {
	// LazyLoading
	$(".img-holder").on("inview", "img[data-src]", function () {
		var $this = $(this);
		$this.attr("src", $this.attr("data-src"));
		// Remove it from the set of matching elements in order to avoid that the handler gets re-executed
		$this.removeAttr("data-src");
	});

	$(".img-holder").on("inview", "source[data-srcset]", function () {
		var $this = $(this);
		$this.attr("srcset", $this.attr("data-srcset"));
		// Remove it from the set of matching elements in order to avoid that the handler gets re-executed
		$this.removeAttr("data-srcset");
	});

	$(".img-holder").on("inview", "img[data-srcset]", function () {
		var $this = $(this);
		$this.attr("srcset", $this.attr("data-srcset"));
		// Remove it from the set of matching elements in order to avoid that the handler gets re-executed
		$this.removeAttr("data-srcset");
	});

	// Popojetí na kotvu z hlášky u Notices projektu
	jQuery("[data-kt-target]").click(function () {
		var targetElement = jQuery(jQuery(this).data("kt-target"));
		var targetBottomOffset = jQuery(this).data("kt-bottom-offset");
		var targetTopOffset = jQuery(this).data("kt-top-offset");
		moveToHtmlTarget(targetElement, targetTopOffset, targetBottomOffset);
	});

	// Odeslání formu přes JS
	jQuery("#submitButton, .submitButton").click(function () {
		if (jQuery(this).hasClass("disabled")) {
			return false;
		}
		jQuery(this).parents("form").submit();
	});

	// Dodá všem iframe elmentům responsivní vlastnost v rámci entryContent (wysiwyg editor)
	jQuery(".entry-content iframe").each(function () {
		jQuery(this).addClass("embed-responsive-item").wrap("<div class=\"embed-responsive embed-responsive-16by9\"></div>");
		jQuery(this).removeAttr("width");
		jQuery(this).removeAttr("height");
	});

	// Dodá všem table elmentům responsivní vlastnost v rámci entryContent (wysiwyg editor)
	jQuery(".entry-content table").each(function () {
		jQuery(this).addClass("table table-responsive");
	});
});

// Funkce pro animované posunutí na prvek v DOM dokumentu
function moveToHtmlTarget(elemnt, topOffset, bottomOffset) {

	var offset = elemnt.offset().top;

	if (topOffset > 0) {
		offset = offset - topOffset;
	}

	if (bottomOffset > 0) {
		offset = offset + bottomOffset;
	}

	jQuery('html, body').animate({
		scrollTop: offset
	}, 1000);

	return this;
}
