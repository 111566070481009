jQuery(document).ready(initialize);
function initialize() {
    //TLAČÍTKA PRO REGISTRACI
    $('[href*="registration"]').click(() => {
        moveToHtmlTarget($('#registration'), 0, 0);
    });

    //CUSTOM SELECTY
    $(".dropdown").each(function () {
        let dropdown = new CustomSelect($(this).attr('id'));
    });

    //CUSTOM SELECTY
    $('[data-difficultySelect]').each(function () {
        console.log($(this));
        let dropdown = new DifficultySelect($(this).data('difficultyselect'));
    });

    //MODAL BOXY
    $("[id^='modal__close']").each(function () {
        let name = $(this).attr('id').substring(13);

        let modal = document.getElementById(name);
        let openButton = document.getElementById('modal__open-' + name);
        let closeButton = document.getElementById('modal__close-' + name);

        if (openButton != undefined) {
            openButton.onclick = () => {
                openModal(name);
            };
        }
        if (closeButton != undefined) {
            closeButton.onclick = () => {
                closeModal(name);
            };
        }
        window.onclick = (event) => {
            if (event.target == modal) {
                closeModal(name);
            }
        };
    });

    //TIMERS
    if ($('[data-timeruntil]').length > 0) {
        timer();
        setInterval(timer, 1000);
    }
}

function timer() {
    $('[data-timeruntil]').each(function () {
        let type = $(this).data('timertype');
        let time = 1 * $(this).data('timeruntil') - Math.floor(now() / 1000);
        if (time > 0) {
            let minutes = Math.floor(time / 60);
            let seconds = time - minutes * 60;
            if (seconds < 10)
                seconds = "0" + seconds;
            time = minutes + ":" + seconds;
        } else {
            if (type === "until"){
                time = "pozdě";
            } else {
                time = "0:00";
            }
        }
        $(this).html(time);
    });
}

function now(){
    return Date.now() - offset;
}

function openModal(id) {
    let modal = document.getElementById(id);
    modal.style.display = "block";
    setTimeout(() => {
        modal.classList.add('open')
    }, 0);
}

function closeModal(id) {
    let modal = document.getElementById(id);
    modal.style.display = "none";
    modal.classList.remove('open');
}

$('#groupLocked').change(function () {
    $('#groupPassword').prop('disabled', !$('#groupLocked').is(":checked"));
});

$(window).bind("pageshow", function(event) {
    if (event.originalEvent.persisted) {
        window.location.reload();
    }
});