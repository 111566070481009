class CustomSelect {
    constructor(name) {
        this.input = document.getElementById(name + "__input");
        this.list = document.getElementById(name + "__list");
        this.valueInput = document.getElementById(name + "__value");

        //setup functions
        this.input.onblur = () => {
            this.close();
        };

        this.input.onfocus = () => {
            this.open();
        };

        this.options = document.getElementsByClassName(name + "__list__item");
        for (let i = 0; i < this.options.length; i++) {
            this.options[i].onclick = () => {
                this.select(i);
            };
        }
    }

    open() {
        this.list.classList.add("dropdown__list__visible");
    }

    close() {
        setTimeout(() => {
            this.list.classList.remove("dropdown__list__visible");
        }, 150);
    }

    select(i) {
        this.input.value = this.options[i].innerHTML.replace(/&lt;/g, "<").replace(/&gt;/g, ">").replace(/&le;/g, "≤").replace(/&ge;/g, "≥");
        if (this.options[i].dataset.value)
            this.valueInput.value = this.options[i].dataset.value;

        for (let i = 0; i < this.options.length; i++) {
            this.options[i].classList.remove("selected");
        }

        this.options[i].classList.add("selected");
    }
}